/* global mixpanel */
export const MONTHLY_COMPETITION_MP_EVENTS = {
    MONTHLY_COMPETITION_VIEWS_COMPETITION_LANDING_PAGE:
        'viewsCompetitionLandingPage',
    MONTHLY_COMPETITION_VIEWS_COMPETITION_REGISTER_PAGE:
        'viewsCompetitionRegisterPage',
    MONTHLY_COMPETITION_REGISTERS_CLASS: 'registersClass',
    MONTHLY_COMPETITION_EDITS_CLASS: 'editsClass',
    STUDENT_NAVIGATES_TO_COMPETITION: 'studentNavigatesToLeaderboard',
    LEAGUE_LEADERBOARD_OVERVIEW_PAGE_VIEW: 'leagueLeaderboardOverviewPageView',
    LEAGUE_LEADERBOARD_CLASSES_PAGE_VIEW: 'leagueLeaderboardClassesPageView',
    LEAGUE_LEADERBOARD_SCHOOLS_PAGE_VIEW: 'leagueLeaderboardSchoolsPageView',
    LEAGUE_LEADERBOARD_STUDENTS_PAGE_VIEW: 'leagueLeaderboardStudentsPageView',
    LEAGUE_LEADERBOARD_YEARLY_PAGE_VIEW: 'leagueLeaderboardLeaguePageView',
    LEAGUE_LEADERBOARD_YEARLY_LEAGUE_TAB_CLICK:
        'leagueLeaderboardLeagueTabClick',
    MONTHLY_COMPETITION_INVITE_LINK_CLICK: 'monthlyCompetitionInviteLinkClick',
    MONTHLY_COMPETITION_TEACHER_CLICKS_ON_COMPETITION_CTA:
        'monthlyCompetitionTeacherClicksOnCompetitionCta',
    MONTHLY_COMPETITION_TEACHER_SEES_COMPETITION_POPUP:
        'monthlyCompetitionTeacherSeesCompetitionPopup',
    MONTHLY_COMPETITION_CLICKER_REGISTER_FROM_HOME_WIDGET:
        'monthlyCompetitionClickerRegisterFromHomeWidget',
    MONTHLY_COMPETITION_CLICKED_LEADERBOARD_FROM_TBL:
        'monthlyCompetitionClickedLeaderboardFromTbl',
    MONTHLY_COMPETITION_CLICKED_INDIVIDUAL_PRACTICE_PAGE:
        'monthlyCompetitionClickedIndividualPracticePage',
};

export const MONTHLY_COMPETITION_METHODS = {
    viewsCompetitionLandingPage() {
        mixpanel.track('Views competition landing page');
    },
    viewsCompetitionRegisterPage() {
        mixpanel.track('Views competition register page');
    },
    registersClass({
        classCode,
        grade,
        state,
        schoolName,
        registrationDate,
        publicClassName,
    }) {
        mixpanel.track('Teacher registers class in competition', {
            'Class ID': classCode,
            'Grade Level': grade,
            State: state,
            'School Name': schoolName,
            'Registration Date': registrationDate,
            'Public Class Name': publicClassName,
        });
    },
    editsClass({ classCode, grade, state, schoolName, publicClassName }) {
        mixpanel.track('Teacher edits class in competition', {
            'Class ID': classCode,
            'Grade Level': grade,
            State: state,
            'School Name': schoolName,
            'Public Class Name': publicClassName,
        });
    },
    studentNavigatesToLeaderboard({ classCode }) {
        mixpanel.track('Student clicks on competition leaderboard', {
            'Class ID': classCode,
        });
    },
    leagueLeaderboardOverviewPageView({ competitionName }) {
        mixpanel.track('League leaderboard: viewed Overview page', {
            'Competition name': competitionName,
        });
    },
    leagueLeaderboardClassesPageView({ competitionName }) {
        mixpanel.track('League leaderboard: viewed Classes page', {
            'Competition name': competitionName,
        });
    },
    leagueLeaderboardSchoolsPageView({ competitionName }) {
        mixpanel.track('League leaderboard: viewed Schools page', {
            'Competition name': competitionName,
        });
    },
    leagueLeaderboardStudentsPageView({ competitionName }) {
        mixpanel.track('League leaderboard: viewed Students page', {
            'Competition name': competitionName,
        });
    },
    leagueLeaderboardLeaguePageView() {
        mixpanel.track('League leaderboard: viewed Leagues page');
    },
    leagueLeaderboardLeagueTabClick({ leagueName }) {
        mixpanel.track(
            'League leaderboard: Clicked on league in league leaderboard',
            {
                League: leagueName,
            },
        );
    },
    monthlyCompetitionInviteLinkClick() {
        mixpanel.track('Click on Invite colleague to League');
    },
    monthlyCompetitionTeacherClicksOnCompetitionCta({ cta }) {
        mixpanel.track('Teacher clicks on Competition CTA', {
            CTA: cta,
        });
    },
    monthlyCompetitionTeacherSeesCompetitionPopup() {
        mixpanel.track('Teacher sees competition popup');
    },
    monthlyCompetitionClickerRegisterFromHomeWidget() {
        mixpanel.track(
            'Teacher clicked register to Competition from Home page widget',
        );
    },
    monthlyCompetitionClickedLeaderboardFromTbl() {
        mixpanel.track('Clicked View Leaderboard from TBL');
    },
    monthlyCompetitionClickedIndividualPracticePage() {
        mixpanel.track('Clicked Individual Practice page');
    },
};
