import { randomArrayElement, randomIntFromRange } from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import MultiplicationTablesWithinRange from '@/core/static-json/multiplicationTablesWithinRanges.json';
import {
    isCorrectFractionAnswer,
    getFractionLayout,
    FractionsOperations,
} from '@/core/math-topics/utils/fractions';
import { KEYBOARD_TYPE } from '@/core/math-topics/TopicsBaseClass';

/**
 * @extends TopicsBaseClass
 */
export default class DividingTopic extends TopicsBaseClass {
    static code = 'TYPE_DIVIDING';
    static icon = '÷';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.dividing';

    /**
     * Divide.
     *
     * @param numberGenerator
     * @returns {{number1: (*|number), number2: (*|number), answer: number}}
     */
    static generateQuestion(numberGenerator) {
        if (numberGenerator.topic === 'divisibilityRules') {
            const { type, numbersInTask, divisibleBy } = numberGenerator;

            let answer;

            const numberToDivideBy = randomArrayElement(divisibleBy);

            const numbers = {
                nr1: null,
                nr2: null,
                nr3: null,
            };

            const maxNumberMapping = {
                2: 50000,
                3: 33000,
                5: 20000,
                4: 25000,
                6: 16000,
                7: 14500,
                8: 12500,
                9: 11000,
                10: 10000,
                11: 9900,
                15: 6600,
                18: 5500,
                25: 4000,
                50: 2000,
                100: 1000,
            };

            const randomPropertyKey = (obj) => {
                const keys = Object.keys(obj);

                return keys[(keys.length * Math.random()) << 0];
            };

            // The game with just one number has a different structure.
            if (numbersInTask === 1) {
                const generateCorrectQuestion = randomIntFromRange(0, 1);

                if (generateCorrectQuestion) {
                    const random =
                        Math.random() * maxNumberMapping[numberToDivideBy];

                    numbers.nr1 =
                        Math.round(random / numberToDivideBy) *
                        numberToDivideBy;
                } else {
                    const randomNumber = randomPropertyKey(maxNumberMapping);

                    numbers.nr1 =
                        randomIntFromRange(1, maxNumberMapping[randomNumber]) *
                        randomNumber;

                    // Making sure that the wrong answers are wrong.
                    while (numbers.nr1 % numberToDivideBy === 0) {
                        numbers.nr1++;
                    }
                }

                answer = generateCorrectQuestion === 1 ? 'Yes' : 'No';
            } else {
                for (let i = 0; i < numbersInTask; i++) {
                    // Keep the correct answer in nr1, rest are false.
                    let randomNumber;

                    if (i === 0) {
                        randomNumber = numberToDivideBy;

                        const random =
                            Math.random() * maxNumberMapping[numberToDivideBy];

                        numbers.nr1 =
                            Math.round(random / numberToDivideBy) *
                            numberToDivideBy;
                    } else {
                        randomNumber = randomPropertyKey(maxNumberMapping);

                        const propertyKey = `nr${i + 1}`;

                        numbers[propertyKey] =
                            randomIntFromRange(
                                1,
                                maxNumberMapping[randomNumber],
                            ) * randomNumber;

                        // Making sure that the wrong answers are wrong.
                        while (numbers[propertyKey] % numberToDivideBy === 0) {
                            numbers[propertyKey]++;
                        }
                    }
                }

                // Randomize the placement of the correct answer.
                const correctAnswer = randomIntFromRange(1, numbersInTask);

                if (correctAnswer !== 1) {
                    const correctValueTemp = numbers.nr1;

                    numbers.nr1 = numbers['nr' + correctAnswer];

                    numbers['nr' + correctAnswer] = correctValueTemp;
                }

                answer = numbers['nr' + correctAnswer];
            }

            return {
                type,
                data: {
                    numbers,
                    numberToDivideBy,
                    numbersInTask,
                },
                answer,
            };
        }
        if (numberGenerator?.topic === 'fractions') {
            const fractionsOperations = new FractionsOperations(
                numberGenerator,
            );

            const { d1, d2 } =
                fractionsOperations.getDenominators(numberGenerator);

            const { n1, n2 } =
                fractionsOperations.getNominators(numberGenerator);

            const { w1, w2 } =
                fractionsOperations.getWholeNumber(numberGenerator);

            const fractionOperationAnswer =
                fractionsOperations.getFractionDividingAnswer(
                    { n1, d1, w1 },
                    { n2, d2, w2 },
                );

            const number1 = { numerator: n1, denominator: d1, wholeNumber: w1 };

            const number2 = { numerator: n2, denominator: d2, wholeNumber: w2 };

            return {
                topic: numberGenerator.topic,
                number1,
                number2,
                answer: fractionOperationAnswer,
            };
        }
        const withRemainder =
            numberGenerator.dividend >= 100 &&
            numberGenerator.subtopic &&
            numberGenerator.subtopic === 'dividingWithRemainder';

        if (withRemainder) {
            if (numberGenerator.dividend === 100) {
                const b = randomIntFromRange(2, 9);

                const c = randomIntFromRange(1, 10);

                const r = randomIntFromRange(1, b - 1);

                const a = b * c + r;

                return {
                    nr1: a,
                    nr2: b,
                    wholeNumber: c,
                    remainder: r,
                };
            }

            if (numberGenerator.dividend === 144) {
                const b = randomIntFromRange(2, 11);

                const c = randomIntFromRange(1, 12);

                const r = randomIntFromRange(1, b - 1);

                const a = b * c + r;

                return {
                    nr1: a,
                    nr2: b,
                    wholeNumber: c,
                    remainder: r,
                };
            }
        }

        let number1 = 0;

        let number2 = 0;

        let answer = 0;

        let divisorType =
            typeof numberGenerator.divisor === 'object'
                ? 'inRangeDivisor'
                : 'fixedDivisor';

        if (!divisorType) {
            divisorType = 'inRangeDivisor';
        }

        let number1Multipliers = 0;

        let countOfNumber1Multipliers = 0;

        let maxDividend = 10;

        switch (divisorType) {
            case 'fixedDivisor':
                if (numberGenerator.divisor > numberGenerator.dividend) {
                    numberGenerator.divisor = numberGenerator.dividend;
                }

                number1 = numberGenerator.divisor;

                number1Multipliers = MultiplicationTablesWithinRange[
                    numberGenerator.dividend
                ][number1].filter((dividend) => dividend <= maxDividend);

                countOfNumber1Multipliers = number1Multipliers.length;

                number2 =
                    number1Multipliers[
                        randomIntFromRange(0, countOfNumber1Multipliers - 1)
                    ];

                answer = number1 * number2;

                return {
                    number1: answer,
                    number2: number1,
                    answer: number2,
                };
            case 'inRangeDivisor':
                const selectedNumberGenerator = { ...numberGenerator };
                if (selectedNumberGenerator.dividend > 100) {
                    // When 1..144 is selected, no matter the selected lang,
                    // we want questions like 132/11, so increase the max.
                    maxDividend = 12;
                }

                if (!selectedNumberGenerator.divisor) {
                    selectedNumberGenerator.divisor = {
                        nr1: 1,
                        nr2: maxDividend,
                    };
                }

                if (
                    selectedNumberGenerator.divisor.nr2 >
                    selectedNumberGenerator.dividend
                ) {
                    selectedNumberGenerator.divisor.nr2 =
                        selectedNumberGenerator.dividend;
                }

                number1 = randomIntFromRange(
                    selectedNumberGenerator.divisor.nr1,
                    selectedNumberGenerator.divisor.nr2,
                );

                number1Multipliers = MultiplicationTablesWithinRange[
                    selectedNumberGenerator.dividend
                ][number1].filter((dividend) => dividend <= maxDividend);

                countOfNumber1Multipliers = number1Multipliers.length;

                number2 =
                    number1Multipliers[
                        randomIntFromRange(0, countOfNumber1Multipliers - 1)
                    ];

                answer = number1 * number2;

                return {
                    number1: answer,
                    number2: number1,
                    answer: number2,
                };
        }
    }

    static getNumberGeneratorName(gameInfo) {
        const { numberGenerator } = gameInfo;

        if (numberGenerator.topic === 'divisibilityRules') {
            return ` - ${this.t(
                'host.create.divisibilityRules.divisibleBy',
            )} ${numberGenerator.divisibleBy.join(', ')}`;
        }

        return this.t('game.gameTypeTitle.dividing');
    }

    static formatQuestion(question, skill, calledIn) {
        const locale = this.locale();

        if (!question) {
            return;
        }

        if (
            skill.numberGenerator.topic === 'divisibilityRules' ||
            question.topic === 'divisibilityRules' ||
            (question.data && question.data.topic === 'divisibilityRules')
        ) {
            const dataNumbers = question?.data?.numbers || [];

            const rawNumbers = Object.values(dataNumbers)
                .filter((question) => question)
                .map((question) => {
                    const preFormattedQuestion = Number(question);
                    return parseFloat(preFormattedQuestion).toLocaleString(
                        locale,
                    );
                });

            const numbers = rawNumbers.join(', ');

            const numberToDivideBy = question?.data?.numberToDivideBy;

            const numbersInTask = question?.data?.numbersInTask;

            const preFormattedNr1 = Number(question?.data?.numbers?.nr1);

            const formattedNr1 =
                parseFloat(preFormattedNr1).toLocaleString(locale);

            if (
                calledIn === 'inGame' ||
                calledIn === 'inPhoneStudentViewExample'
            ) {
                if (numbersInTask === 1) {
                    // prettier-ignore
                    return `<div class="divisibility-rules-question">
                                    <span>${this.t(
                                        'host.create.divisibilityRules.isNumberDivisible',
                                    )}</span>
                                    <div class="question">${this.t(
                                        'host.create.divisibilityRules.oneNumberInTask.formatQuestion',
                                        {
                                            numberToDivideBy,
                                            number: formattedNr1,
                                        },
                                    )}
                                    </div>
                        </div>`;
                }
                // prettier-ignore
                return `<div class="divisibility-rules-question">
                                    <span>${this.t('host.create.divisibilityRules.clickOnDivisibleNumber')}</span>
                                    <div class="question">${this.t('host.create.divisibilityRules.formatQuestion',
                    {
                        numberToDivideBy,
                    })}
                                    </div>
                        </div>`;
            }

            if (calledIn === 'playerStats') {
                if (numbersInTask === 1) {
                    return this.t(
                        'host.create.divisibilityRules.oneNumberInTask.formatQuestionInPlayerStats',
                        {
                            numberToDivideBy,
                            number: formattedNr1,
                        },
                    );
                }

                return this.t(
                    'host.create.divisibilityRules.formatQuestionInPlayerStats',
                    {
                        numberToDivideBy,
                        numbers,
                    },
                );
            }

            const numbersInSeparateRows = rawNumbers.join('<br />');

            return this.t(
                'host.create.divisibilityRules.formatQuestionInExamples',
                {
                    numberToDivideBy,
                    numbers: numbersInSeparateRows,
                },
            );
        } else if (
            question?.topic === 'fractions' ||
            question?.data?.topic === 'fractions'
        ) {
            const number1 = question?.number1 || question?.data?.number1;

            const number2 = question?.number2 || question?.data?.number2;

            return `
                <div style='width: 100%; display: flex; align-items: center; justify-content: center; column-gap: 5px'>
                      ${getFractionLayout(number1, false, false, calledIn)}
                    ÷
                      ${getFractionLayout(number2, false, false, calledIn)}
                </div>
                `;
        } else {
            let nr1 = null;

            let nr2 = null;

            let nr2base = null;

            if (question.number1 || question.number1 === 0) {
                nr1 = parseFloat(question.number1).toLocaleString(locale);

                nr2 = parseFloat(question.number2).toLocaleString(locale);

                nr2base = parseFloat(question.number2);
            } else {
                nr1 = parseFloat(question.nr1).toLocaleString(locale);

                nr2 = parseFloat(question.nr2).toLocaleString(locale);

                nr2base = parseFloat(question.nr2);
            }

            // Adds brackets to the questions like this:
            // 5 - -5 so it'd be 5 - (-5).
            if (nr2base < 0) {
                nr2 = `(${nr2})`;
            }

            return `${nr1} ÷ ${nr2}`;
        }
    }

    static generateQuestionHtmlString(question, calledIn) {
        const locale = this.locale();

        if (
            question.topic === 'divisibilityRules' ||
            (question.data && question.data.topic === 'divisibilityRules')
        ) {
            const dataNumbers = question?.data?.numbers || [];

            const rawNumbers = (Object.values(dataNumbers) || [])
                .filter((question) => question)
                .map((question) => {
                    const preFormattedQuestion = Number(question);
                    return parseFloat(preFormattedQuestion).toLocaleString(
                        locale,
                    );
                });

            const numbers = rawNumbers.join(', ');

            const { numberToDivideBy, numbersInTask } = question.data;

            const preFormattedNr1 = Number(question.data.numbers.nr1);

            const formattedNr1 =
                parseFloat(preFormattedNr1).toLocaleString(locale);

            if (calledIn === 'inGame') {
                if (numbersInTask === 1) {
                    // prettier-ignore
                    return `<div class="divisibility-rules-question">
                                    <span>${this.t('host.create.divisibilityRules.isNumberDivisible')}</span>
                                    <div class="question">${this.t(
                    'host.create.divisibilityRules.oneNumberInTask.formatQuestion',
                    {
                        numberToDivideBy,
                        number: formattedNr1,
                    })}
                                    </div>
                        </div>`;
                }
                // prettier-ignore
                return `<div class="divisibility-rules-question">
                                    <span>${this.t('host.create.divisibilityRules.clickOnDivisibleNumber')}</span>
                                    <div class="question">${this.t('host.create.divisibilityRules.formatQuestion',
                {
                    numberToDivideBy,
                })}
                                    </div>
                        </div>`;
            }

            if (calledIn === 'playerStats') {
                if (numbersInTask === 1) {
                    return this.t(
                        'host.create.divisibilityRules.oneNumberInTask.formatQuestionInPlayerStats',
                        {
                            numberToDivideBy,
                            number: formattedNr1,
                        },
                    );
                }

                return this.t(
                    'host.create.divisibilityRules.formatQuestionInPlayerStats',
                    {
                        numberToDivideBy,
                        numbers,
                    },
                );
            }

            if (calledIn === 'inPhoneStudentViewExample') {
                // Phone view uses its description,
                // so we don't insert the help text here.
                if (numbersInTask === 1) {
                    return `<div class='divisibility-rules-example'><span>${this.t(
                        'host.create.divisibilityRules.oneNumberInTask.formatQuestion',
                        {
                            numberToDivideBy,
                            number: formattedNr1,
                        },
                    )}</span><div class="yes-no-options">
                                <div class="option">${this.t(
                                    'general.yes',
                                )}</div>
                                <div class="option">${this.t(
                                    'general.no',
                                )}</div>
                            </div>`;
                }

                let options = '';

                rawNumbers.forEach((option) => {
                    options += `<div class="option">${option}</div>`;
                });

                return `<div class='divisibility-rules-example'><span>${this.t(
                    'host.create.divisibilityRules.formatQuestion',
                    {
                        numberToDivideBy,
                    },
                )}</span><div class="options">${options}</div>`;
            }

            const numbersInSeparateRows = rawNumbers.join('<br />');

            return this.t(
                'host.create.divisibilityRules.formatQuestionInExamples',
                {
                    numberToDivideBy,
                    numbers: numbersInSeparateRows,
                },
            );
        }

        let nr1 = null;

        let nr2 = null;

        let nr2base = null;

        if (question.number1 || question.number1 === 0) {
            nr1 = parseFloat(question.number1).toLocaleString(locale);

            nr2 = parseFloat(question.number2).toLocaleString(locale);

            nr2base = parseFloat(question.number2);
        } else {
            nr1 = parseFloat(question.nr1).toLocaleString(locale);

            nr2 = parseFloat(question.nr2).toLocaleString(locale);

            nr2base = parseFloat(question.nr2);
        }
        // Adds brackets to the questions like this:
        // 5 - -5 so it'd be 5 - (-5).
        if (nr2base < 0) {
            nr2 = `(${nr2})`;
        }

        return `${nr1} ${this.icon} ${nr2}`;
    }

    static getAnswerInputType(numberGenerator) {
        const { topic, subtopic, numbersInTask } = numberGenerator;

        if (topic === 'fractions') {
            return ANSWER_INPUT_TYPE.MIXED;
        }

        if (subtopic === 'dividingWithRemainder') {
            return ANSWER_INPUT_TYPE.REMAINDER;
        }

        if (topic === 'divisibilityRules') {
            if (numbersInTask === 1) return ANSWER_INPUT_TYPE.YES_OR_NO;
            return ANSWER_INPUT_TYPE.SELECT_OPTION;
        }

        return ANSWER_INPUT_TYPE.REGULAR;
    }

    static getKeyboardType(numberGenerator) {
        if (numberGenerator.topic === 'divisibilityRules') {
            return KEYBOARD_TYPE.ENTER;
        }

        return KEYBOARD_TYPE.NUMERIC;
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        if (numberGenerator.subtopic === 'dividingWithRemainder') {
            return (
                question.wholeNumber === Number(answer.wholeNumber) &&
                question.remainder === Number(answer.remainder)
            );
        }

        if (numberGenerator.topic === 'divisibilityRules') {
            if (numberGenerator.numbersInTask === 1) {
                return answer === question.answer;
            }
        } else if (numberGenerator.topic === 'fractions') {
            return isCorrectFractionAnswer(answer, question.answer);
        }

        return question.answer === Number(answer);
    }

    static answerData(question, answer, skill) {
        if (skill.numberGenerator.subtopic === 'dividingWithRemainder') {
            return {
                wholeNumber: question.wholeNumber,
                remainder: question.remainder,
                subtopic: skill.numberGenerator.subtopic,
            };
        }

        if (skill.numberGenerator.topic === 'divisibilityRules') {
            return {
                ...question.data,
                type: skill.numberGenerator.type,
            };
        }

        if (skill.numberGenerator.topic === 'fractions') {
            const { answer, number1, number2 } = question;

            const {
                type,
                numberGenerator: { topic, subtopic },
            } = skill;

            return {
                number1,
                number2,
                answer,
                topic,
                subtopic,
            };
        }

        return super.answerData(question, answer, skill);
    }

    static generatePlayerAnswerHtml(playerAnswer, numberGenerator, question) {
        const isWrongAnswer = !question?.correct;

        if (
            numberGenerator.topic === 'divisibilityRules' &&
            numberGenerator.numbersInTask === 1
        ) {
            if (!playerAnswer) {
                return '';
            }
            return `<span class="wrong-answer">${this.t(
                `general.${playerAnswer.toLowerCase()}`,
            )}</span>`;
        }

        if (
            numberGenerator.subtopic === 'dividingWithRemainder' &&
            typeof playerAnswer === 'object'
        ) {
            if (!playerAnswer.wholeNumber && !playerAnswer.remainder) {
                return ` = <span class="wrong-answer"> - </span>`;
            }

            return ` = <span class="wrong-answer">
                        <span>${playerAnswer.wholeNumber || '-'}</span>
                        <span>${this.t('topics.remainderAbbreviation')}</span>
                        <span>${playerAnswer.remainder || '-'}</span>
                    </span>`;
        }

        if (numberGenerator.topic === 'fractions') {
            return getFractionLayout(playerAnswer, isWrongAnswer, true);
        }

        return super.generatePlayerAnswerHtml(
            playerAnswer,
            numberGenerator,
            question,
        );
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        const topic = question?.topic || numberGenerator?.topic;

        if (topic === 'fractions') {
            const correctAnswer = question.answer || question.data.answer;

            return getFractionLayout(correctAnswer, false, true, calledIn);
        }

        if (
            question.data?.subtopic === 'dividingWithRemainder' ||
            numberGenerator?.subtopic === 'dividingWithRemainder' ||
            numberGenerator?.numberGenerator?.subtopic ===
                'dividingWithRemainder'
        ) {
            return ` = <span class="correct-answer">
                    <span>${
                        question.wholeNumber ||
                        question.data?.wholeNumber ||
                        '-'
                    }</span>
                    <span>${this.t('topics.remainderAbbreviation')}</span>
                    <span>${
                        question.remainder || question.data?.remainder || '-'
                    }</span>
                </span>`;
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static showArrowButtons(numberGenerator) {
        return (
            numberGenerator.topic === 'fractions' ||
            numberGenerator.subtopic === 'dividingWithRemainder'
        );
    }

    static resetPlayerAnswer(numberGenerator) {
        if (
            numberGenerator.subtopic &&
            numberGenerator.subtopic === 'dividingWithRemainder'
        ) {
            return {
                wholeNumber: '',
                remainder: '',
            };
        }

        if (numberGenerator.topic === 'fractions') {
            return {
                wholeNumber: '',
                numerator: '',
                denominator: '',
            };
        }

        return '';
    }
}
